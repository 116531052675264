import { gql, useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import clientOnly from 'components/clientOnly';
import Button from 'components/common/Button';
import Tiles from 'components/common/Tiles';
import CampaignPageSponsor from './CampaignPageSponsor';

const GET_SPONSORS = gql`
  query GetSponsors($campaignId: String!, $where: SequelizeJSON!, $limit: Int, $skip: Int) {
    findCampaigns(id: $campaignId) {
      id
      sponsors(where: $where, order: "reverse:createdAt", limit: $limit, offset: $skip) {
        id
        name
        amount
        createdAt
      }
    }
  }
`;

const CampaignPageActivityFeedSponsors = ({
  allowLoadMore,
  className,
  emptyLabel,
  filter,
  initialLimit,
  loadMoreLabel,
  loadMoreLimit,
  showFundraiserName,
  showTeamName,
  campaignId,
}) => {
  const [hasMore, setHasMore] = useState(true);

  const { data, loading, fetchMore } = useQuery(GET_SPONSORS, {
    variables: { campaignId, where: filter, limit: initialLimit, skip: 0 },
    onCompleted: ({ findCampaigns = [] }) => {
      const sponsors = findCampaigns[0]?.sponsors ?? [];
      if (sponsors.length < initialLimit) setHasMore(false);
    },
  });

  const sponsors = data?.findCampaigns?.[0]?.sponsors ?? [];

  const handleLoadMore = useCallback(
    () =>
      fetchMore({
        variables: {
          limit: loadMoreLimit,
          skip: sponsors.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const newSponsors = fetchMoreResult?.findCampaigns?.[0]?.sponsors ?? [];

          // We've reached the end
          if (newSponsors.length < loadMoreLimit) setHasMore(false);

          const updatedSponsors = [...(prev.findCampaigns[0]?.sponsors ?? []), ...newSponsors];

          return {
            ...prev,
            findCampaigns: [
              {
                ...prev.findCampaigns[0],
                sponsors: updatedSponsors,
              },
            ],
          };
        },
      }),
    [fetchMore, loadMoreLimit, sponsors.length]
  );

  // Still loading
  if (sponsors.length === 0 && loading) return null;

  if (sponsors.length === 0) {
    return (
      <div className={className}>
        <p className="text-gray-600 text-lg text-center">{emptyLabel}</p>
      </div>
    );
  }

  return (
    <div className="sm:container max-w-7xl">
      <div className={className}>
        <Tiles columns={1} spacing="xs">
          {sponsors.map((sponsor) => (
            <motion.div
              key={sponsor.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              <CampaignPageSponsor
                {...sponsor}
                showFundraiserName={showFundraiserName}
                showTeamName={showTeamName}
              />
            </motion.div>
          ))}
        </Tiles>
        {allowLoadMore && hasMore && (
          <div className="mt-8 sm:mt-12 text-center">
            <Button
              as="button"
              type="button"
              onClick={handleLoadMore}
              color="gray-300"
              outline
              className="rounded-full font-medium"
            >
              {loadMoreLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

CampaignPageActivityFeedSponsors.propTypes = {
  filter: PropTypes.oneOfType([
    PropTypes.shape({ teamId: PropTypes.string.isRequired }),
    PropTypes.shape({ fundraiserId: PropTypes.string.isRequired }),
  ]).isRequired,
  campaignId: PropTypes.string.isRequired,
  allowLoadMore: PropTypes.bool,
  className: PropTypes.string,
  emptyLabel: PropTypes.node,
  initialLimit: PropTypes.number,
  loadMoreLabel: PropTypes.node,
  loadMoreLimit: PropTypes.number,
  showFundraiserName: PropTypes.bool,
  showTeamName: PropTypes.bool,
};

CampaignPageActivityFeedSponsors.defaultProps = {
  allowLoadMore: false,
  className: '',
  emptyLabel: 'Check back soon!',
  initialLimit: 5,
  loadMoreLabel: 'Show More',
  loadMoreLimit: 10,
  showFundraiserName: false,
  showTeamName: false,
};

export default clientOnly(CampaignPageActivityFeedSponsors);
