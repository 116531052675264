import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import { useCampaignPage } from 'context/CampaignPage';
import Pill from 'components/common/Pill';
import CampaignPageActivityFeed from './CampaignPageActivityFeed';
import CampaignPageActivityFeedSponsors from './CampaignPageActivityFeedSponsors';

const GET_FUNDRAISER = gql`
  query GetFundraiser($id: String!) {
    findFundraisers(id: $id) {
      id
      donationCount
    }
  }
`;

const GET_TEAM = gql`
  query GetTeam($id: String!) {
    findTeams(id: $id) {
      id
      donationCount
    }
  }
`;

const GET_SPONSORS = gql`
  query GetSponsors($campaignId: String!, $where: SequelizeJSON!) {
    findCampaigns(id: $campaignId) {
      sponsors(where: $where) {
        id
      }
    }
  }
`;

const CampaignPageFundraiserContributions = ({ className }) => {
  const { fundraiserId, teamId, pageType, campaignId } = useCampaignPage();
  const isTeam = pageType === 'team';

  const { data } = useQuery(isTeam ? GET_TEAM : GET_FUNDRAISER, {
    variables: { id: isTeam ? teamId : fundraiserId },
  });

  const fundraiser = useMemo(
    () => data?.[isTeam ? 'findTeams' : 'findFundraisers'][0],
    [isTeam, data]
  );

  const { data: sponsorsData } = useQuery(GET_SPONSORS, {
    variables: {
      campaignId: campaignId,
      where: { [isTeam ? 'teamId' : 'fundraiserId']: isTeam ? teamId : fundraiserId },
    },
  });

  const sponsors = useMemo(() => sponsorsData?.findCampaigns[0]?.sponsors ?? [], [sponsorsData]);

  if (!fundraiser) return null;

  return (
    <div className={className}>
      {sponsors.length > 0 && (
        <>
          <h2 className="text-xl font-medium mb-4">
            Sponsors <Pill className="ml-1">{sponsors.length}</Pill>
          </h2>
          <CampaignPageActivityFeedSponsors
            className="mt-8 -mx-6"
            campaignId={campaignId}
            filter={{ [isTeam ? 'teamId' : 'fundraiserId']: isTeam ? teamId : fundraiserId }}
            showFundraiserName={isTeam}
            allowLoadMore
          />
        </>
      )}

      <h2 className="text-xl font-medium mb-4 mt-12 md:mt-16">
        Donations <Pill className="ml-1">{fundraiser.donationCount}</Pill>
      </h2>
      <CampaignPageActivityFeed
        className="mt-8 -mx-6"
        filter={{ [isTeam ? 'teamId' : 'fundraiserId']: fundraiser.id }}
        showFundraiserName={isTeam}
        allowLoadMore
        allowReply
      />
    </div>
  );
};

CampaignPageFundraiserContributions.propTypes = {
  className: PropTypes.string,
};

CampaignPageFundraiserContributions.defaultProps = {
  className: '',
};

export default CampaignPageFundraiserContributions;
