import cx from 'classnames';
import Link from 'components/common/Link';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Avatar from 'components/common/Avatar';
import Card from 'components/common/Card';
import Tiles from 'components/common/Tiles';
import useBreakpoint from 'hooks/useBreakpoint';
import { formatCurrency, formatInitials, formatTimeAgo } from 'lib/formatters';

const CampaignPageSponsor = ({ name, amount, createdAt, snapshotLink }) => {
  const mobile = !useBreakpoint('md');
  const displayNameInitials = useMemo(() => formatInitials(name), [name]);

  return (
    <Card
      radius={mobile ? 'none' : 'md'}
      as={snapshotLink ? Link : 'div'}
      depth="none"
      padding={mobile ? '2xs' : 'min'}
      border
      href={snapshotLink && snapshotLink.href}
    >
      <div className="relative overflow-hidden -my-3 sm:my-px -mx-1 sm:mx-px">
        <div className="w-20 flex justify-center absolute inset-y-10 left-0 z-0"></div>
        <div className="relative z-10 flex flex-col items-stretch gap-y-4 mb-4">
          <div className={cx('flex -ml-4 pt-5 px-4')}>
            <div className="pl-4 w-16 shrink-0">
              <Avatar size="md" initials={displayNameInitials} themed />
            </div>
            <div className="pl-4 grow">
              <p className="leading-snug">
                <strong className="font-medium">{name}</strong> credited a{' '}
                <strong className="font-medium">{formatCurrency(amount)}</strong> sponsorship.
              </p>

              <Tiles className="py-1 leading-tight text-sm text-gray-600" spacing="sm">
                <p>{formatTimeAgo(createdAt)}</p>
              </Tiles>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

CampaignPageSponsor.propTypes = {
  amount: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  snapshotLink: PropTypes.shape({ href: PropTypes.string }),
};

CampaignPageSponsor.defaultProps = {
  snapshotLink: null,
};

export default CampaignPageSponsor;
