import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import cx from 'classnames';

import useBreakpoint from 'hooks/useBreakpoint';
import { serializeToJSX } from 'lib/serializeTiptap';
import { useCampaignPage } from 'context/CampaignPage';

const GET_CAMPAIGN = gql`
  query GetCampaignPageFundraiserStory(
    $campaignId: String!
    $fundraiserWhere: SequelizeJSON
    $teamWhere: SequelizeJSON
    $isFundraiser: Boolean!
    $isTeam: Boolean!
  ) {
    findCampaigns(id: $campaignId) {
      id
      fundraiserDefaultStory
      fundraiserDefaultStoryTitle
      fundraisers(where: $fundraiserWhere) @include(if: $isFundraiser) {
        id
        story
        storyTitle
      }
      teamDefaultStory
      teamDefaultStoryTitle
      teams(where: $teamWhere) @include(if: $isTeam) {
        id
        story
        storyTitle
      }
    }
  }
`;

const CampaignPageFundraiserStory = ({ className, canEdit }) => {
  const mobile = !useBreakpoint('sm');
  const { campaignId, teamId, fundraiserId, pageType, setIsEditing } = useCampaignPage();

  const { data } = useQuery(GET_CAMPAIGN, {
    variables: {
      campaignId,
      fundraiserWhere: { id: fundraiserId },
      teamWhere: { id: teamId },
      isFundraiser: pageType === 'fundraiser',
      isTeam: pageType === 'team',
    },
  });

  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const fundraiser = useMemo(() => campaign?.fundraisers?.[0], [campaign]);
  const team = useMemo(() => campaign?.teams?.[0], [campaign]);

  const { title, story } = useMemo(() => {
    if (pageType === 'fundraiser') {
      return {
        title: fundraiser?.storyTitle || campaign?.fundraiserDefaultStoryTitle,
        story: fundraiser?.story || campaign?.fundraiserDefaultStory,
      };
    }

    return {
      title: team?.storyTitle || campaign?.teamDefaultStoryTitle,
      story: team?.story || campaign?.teamDefaultStory,
    };
  }, [campaign, team, fundraiser, pageType]);

  if (!campaign) return null;

  return (
    <div className={className}>
      {title && (
        <div className={cx({ 'flex items-center mb-5': !mobile && canEdit })}>
          <h2
            className={cx('text-center md:text-left text-2xl leading-tight font-medium', {
              'mb-4': mobile || !canEdit,
              'mt-2': !mobile && canEdit,
            })}
          >
            {title}
          </h2>
          <div className="sm:hidden w-16 h-1 rounded-full bg-theme-secondary mx-auto mb-8" />
          {canEdit && !mobile && (
            <button
              onClick={() => setIsEditing(true)}
              type="button"
              className="border border-teal-500 text-teal-500 py-2 px-3 font-medium rounded-full ml-auto"
            >
              Edit Page
            </button>
          )}
        </div>
      )}
      {story && <div className="leading-relaxed rich-text">{serializeToJSX(story)}</div>}
    </div>
  );
};

CampaignPageFundraiserStory.propTypes = {
  className: PropTypes.string,
  canEdit: PropTypes.bool,
};

CampaignPageFundraiserStory.defaultProps = {
  className: '',
  canEdit: false,
};

export default CampaignPageFundraiserStory;
